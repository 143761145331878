import { RiExternalLinkLine } from "react-icons/ri";
import { Text, Heading, Flex } from "@chakra-ui/layout";
import { GiButterflyWarning } from "react-icons/gi";
import * as React from 'react';
export default {
  RiExternalLinkLine,
  Text,
  Heading,
  Flex,
  GiButterflyWarning,
  React
};