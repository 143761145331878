import { TzuyuAvatar, Xetera, Dubu } from "@src/components/Avatars";
import { YooYikes, SanaKek } from "@src/components/Emotes";
import * as React from 'react';
export default {
  TzuyuAvatar,
  Xetera,
  Dubu,
  YooYikes,
  SanaKek,
  React
};