import { StaticImage } from "gatsby-plugin-image";
import { Kekw, Omegalul } from "@src/components/Emotes";
import { Link } from "@chakra-ui/layout";
import { ThisArrow } from "@src/components/Emotes";
import { RiChat1Fill } from "react-icons/ri";
import * as React from 'react';
export default {
  StaticImage,
  Kekw,
  Omegalul,
  Link,
  ThisArrow,
  RiChat1Fill,
  React
};