import openYourEyes from "../../../../content/blog/thoughts-on-go/null_pointer.mp4";
import { Clap, ThumbsDown } from "@src/components/Emotes";
import { RobPike } from "@src/components/Avatars";
import * as React from 'react';
export default {
  openYourEyes,
  Clap,
  ThumbsDown,
  RobPike,
  React
};