import Wa from "../../../../content/blog/whatsapp-sucks/whatsapp.jpg";
import { Xetera } from "@src/components/Avatars";
import { Angery, Whatsapp, Downvote, ProllyNod, Uhuh, PeepoBoomer } from "@src/components/Emotes";
import WeSmart from "../../../../content/blog/whatsapp-sucks/weSmart.png";
import { RiChat1Fill } from "react-icons/ri";
import * as React from 'react';
export default {
  Wa,
  Xetera,
  Angery,
  Whatsapp,
  Downvote,
  ProllyNod,
  Uhuh,
  PeepoBoomer,
  WeSmart,
  RiChat1Fill,
  React
};