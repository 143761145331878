import { RiQuestionLine } from "react-icons/ri";
import { DubuConfused } from "@src/components/Avatars";
import { ThisTbh, ThisArrow } from "@src/components/Emotes";
import * as React from 'react';
export default {
  RiQuestionLine,
  DubuConfused,
  ThisTbh,
  ThisArrow,
  React
};