import { Drac, Marco } from "@src/components/Avatars";
import ProofXet from "../../../../content/blog/india-banned-my-site/proof-xet.png";
import ProofOriginal from "../../../../content/blog/india-banned-my-site/proof-original.png";
import MaverickWolf from "../../../../content/blog/india-banned-my-site/maverick-wolf.png";
import WindowsUser from "../../../../content/blog/india-banned-my-site/windows-user.png";
import Sky from "../../../../content/blog/india-banned-my-site/sky-avatar.png";
import ProofSky from "../../../../content/blog/india-banned-my-site/proof-sky.png";
import * as React from 'react';
export default {
  Drac,
  Marco,
  ProofXet,
  ProofOriginal,
  MaverickWolf,
  WindowsUser,
  Sky,
  ProofSky,
  React
};